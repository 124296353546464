<template>
  <div class="d-flex align-items-center justify-content-center closing-meeting-container">
    <div class="text-center">
      <h3 class="mb-2">
        {{ $t('rooms.finished') }}
      </h3>
      <b-button v-if="$route.name != 'Resumen'" variant="primary" @click="goBack">
        {{ $t('rooms.action.return') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  name: 'RoomClosedMeeting',
  components: { BButton },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goBack(){
      
      if(this.room.widget){
        this.$emit('go-preparing')
        return
      }
        this.$router.replace({
        name:'Resumen', 
        params: { communityId: this.$route.params.communityId }
        });
    },

  }
};
</script>
