<template>
  <b-row class="start-meeting-row align-items-center justify-content-center">
    <b-col class="meeting-container">
      <div id="meet" />
    </b-col>
    <h4 v-if="isLoading" class="text-center room-message">
      {{ $t("rooms.joining") }}
    </h4>
  </b-row>
</template>
<script>
import { BRow, BCol } from "bootstrap-vue";
import { getImageResource } from "@/@core/utils/image-utils";
import Vue from "vue";
import { randomString } from '@/@core/utils/utils';

export default {
  name: "RoomStartMeeting",
  components: { BRow, BCol },
  props: {
    room: {
      type: Object,
      required: true,
    },
    initialConfiguration: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      token: null,
      isLoading: true,
    };
  },
  computed: {
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
    collectiveName() {
      return this.$store.getters.currentCollective.name;
    },
    // Long room names are not allowed
    roomName() {
      return this.room?.name?.replace(/:/g, " ").replace(/-/g, "").replace(/"/g, "") || randomString(10);
    },
  },
  sockets: {
    roomToken(token) {
      this.token = token;
      this.mountConferencing();
    },
  },
  methods: {
    mountConferencing() {

      const options = {
        roomName: `vpaas-magic-cookie-2fa4c4e2cbe04465929e43f161fdc853/${this.room.key}`,
        interfaceConfigOverwrite: {
          DEFAULT_BACKGROUND: "#202124",
        },
        jwt: this.token,
        parentNode: document.querySelector("#meet"),
        onload: () => {
          this.isLoading = false;
        },
      };

      const api = new window.JitsiMeetExternalAPI("8x8.vc", options);
      api.addListener("videoConferenceJoined", (args) => {
        this.$store.state.userData.member.jitsiID = args.id;

        this.$store.dispatch("addAnalytic", {
          morphType: "conferencing",
          action: "joined",
          content: {
            username: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
            memberKey: this.$store.getters.loggedMember.key,
            communitySlug: this.$store.getters.currentCollective.slug,
          },
        });
      });

      api.on("readyToClose", () => {
        this.$emit("leave-meeting");
      });

      api.on("videoConferenceLeft", (args) => {
        this.$store.dispatch("addAnalytic", {
          morphType: "conferencing",
          action: "left",
          content: {
            username: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
            memberKey: this.$store.getters.loggedMember.key,
            communitySlug: this.$store.getters.currentCollective.slug,
          },
        });
      });

      api.on("audioMuteStatusChanged", (args) => {
        this.$store.dispatch("addAnalytic", {
          morphType: "conferencing",
          action: "muted",
          content: {
            username: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
            memberKey: this.$store.getters.loggedMember.key,
            communitySlug: this.$store.getters.currentCollective.slug,
            isMuted: args.muted,
          },
        });
      });

      api.on("screenSharingStatusChanged", (args) => {
        this.$store.dispatch("addAnalytic", {
          morphType: "conferencing",
          action: "screenSharing",
          content: {
            username: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
            memberKey: this.$store.getters.loggedMember.key,
            communitySlug: this.$store.getters.currentCollective.slug,
            isSharing: args.on,
          },
        });
      });

      api.on("incomingMessage", (args) => {
        if (this.$store.getters.loggedMember.jitsiID === args.from) {
          this.$store.dispatch("addAnalytic", {
            morphType: "conferencing",
            action: "message",
            content: {
              username: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
              memberKey: this.$store.getters.loggedMember.key,
              communitySlug: this.$store.getters.currentCollective.slug,
              message: args.message,
            },
          });
        }
      });

      api.on("displayNameChange", (args) => {
        if (this.$store.getters.loggedMember.jitsiID === args.from) {
          this.$store.dispatch("addAnalytic", {
            morphType: "conferencing",
            action: "changedDisplayName",
            content: {
              username: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
              memberKey: this.$store.getters.loggedMember.key,
              communitySlug: this.$store.getters.currentCollective.slug,
              displayName: args.displayName,
            },
          });
        }
      });
    },
  },
  mounted() {
      this.$socket.emit("getToken", {
        communitySlug: this.$store.getters.currentCollective.slug,
        userInfo: {
          key: this.$store.getters.loggedMember.key,
          email: this.$store.getters.loggedMember.email,
          name: `${this.$store.getters.loggedMember.name} ${this.$store.getters.loggedMember.surname}`,
          avatar: this.$store.getters.loggedMember.avatarURL,
          moderator: this.$store.getters.currentCollective.isStaff
            ? "true"
            : "false",
        },
      });
  },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/pages/jitsi.scss";
.room-message {
  position: absolute;
  top: 50%;
}

.meeting-container {
  z-index: 9;
}
</style>
