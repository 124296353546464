<template>
  <b-row class="align-items-center room-row flex-column flex-md-row py-2 px-3">
    <b-col class="p-0" cols="12" lg="4">
      <h1>{{ translate(room.name) }}</h1>
      <div class="mt-2 d-none d-lg-block">
        <div class="h2">
          {{ $t('rooms.ready-to-join') }}
        </div>
        <div>
          <b-alert :show="waitingState" variant="primary">
            {{ $t('rooms.waiting') }}
          </b-alert>
          <b-button
            v-if="url"
            variant="primary"
            class="mr-1"
            @click="handleBBBMeeting"
          >
            {{ $t('rooms.action.start-meeting') }}
          </b-button>
          <b-button
            v-else
            variant="primary"
            class="mr-1"
            @click="handleStartMeeting"
          >
            {{ $t('rooms.action.start-meeting') }}
          </b-button>
          <b-button 
            v-if="!room.widget" 
            variant="secondary" 
            @click="handleLeaveMeeting"
          >
            {{ $t('action.cancel') }}
          </b-button>
        </div>
      </div>
    </b-col>
    <b-col class="pr-0 pl-0 pl-md-2 pl-lg-3 mt-2 mt-lg-0">
      <div class="video-container">
        <video
          ref="videoElement"
          class="video-player"
          autoplay="true"
        />
        <div v-if="errorMessage" class="error-message">
          {{ $t(`rooms.error-message.${errorMessage}`) }}
        </div>
        <av-media
          v-if="isMicOn"
          class="audio-visualizer"
          :media="audioStream"
          type="frequ"
          :canv-width="30"
          :canv-height="30"
          :frequ-line-cap="true"
          :frequ-lnum="3"
          :line-width="6"
        />
        <div class="actions-container">
          <b-button
            :variant="isMicOn ? 'outline-light' : 'danger'"
            class="toggle-action toggle-video"
            @click="toggleMic"
          >
            <feather-icon :icon="isMicOn ? 'MicIcon' : 'MicOffIcon'" />
          </b-button>
          <b-button
            :variant="isVideoOn ? 'outline-light' : 'danger'"
            class="toggle-action toggle-audio ml-1"
            @click="toggleVideo"
          >
            <feather-icon :icon="isVideoOn ? 'VideoIcon' : 'VideoOffIcon'" />
          </b-button>
        </div>
      </div>
      <div class="mt-2 d-block d-lg-none">
        <div class="h2">
          {{ $t('rooms.ready-to-join') }}
        </div>
        <div>
          <b-alert :show="waitingState" variant="primary">
            {{ $t('rooms.waiting') }}
          </b-alert>
          <b-button v-if="url" variant="primary" @click="handleBBBMeeting">
            {{ $t('rooms.action.start-meeting') }}
          </b-button>
          <b-button v-if="!url" variant="primary" @click="handleStartMeeting">
            {{ $t('rooms.action.start-meeting') }}
          </b-button>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BAlert,
} from 'bootstrap-vue';
import AvMedia from 'vue-audio-visual/src/components/AvMedia';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

const ERROR_MESSAGES = Object.freeze({
  NoMedia: 'no-media',
  VideoFailed: 'video-failed',
  AudioFailed: 'audio-failed',
});

export default {
  name: 'RoomPreparationMeeting',
  components: {
    AvMedia,
    BRow,
    BCol,
    BButton,
    BAlert,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
    isBbb: Boolean,
    url: {
      type: String,
      default: null,
    },
    waitingState: Boolean,
  },
  data() {
    return {
      isVideoOn: true,
      isMicOn: true,
      videoStream: null,
      audioStream: null,
      errorMessage: null,
    };
  },
  async mounted() {
    this.requestUserVideo();
    this.requestUserAudio();
  },
  destroyed() {
    this.stopVideoStream();
    this.stopAudioStream();
  },
  methods: {
    async requestUserVideo() {
      if (!navigator.mediaDevices.getUserMedia) {
        this.errorMessage = ERROR_MESSAGES.NoMedia;
        this.isVideoOn = false;
        return;
      }
      if (this.errorMessage === ERROR_MESSAGES.NoMedia) {
        this.errorMessage = null;
      }
      try {
        const video = this.$refs.videoElement;
        this.videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        video.srcObject = this.videoStream;
      } catch {
        this.errorMessage = ERROR_MESSAGES.VideoFailed;
        this.isVideoOn = false;
      }
    },
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    stopVideoStream() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
      }
    },
    async requestUserAudio() {
      if (!navigator.mediaDevices.getUserMedia) {
        this.errorMessage = ERROR_MESSAGES.NoMedia;
        this.isMicOn = false;
        return;
      }
      if (this.errorMessage === ERROR_MESSAGES.NoMedia) {
        this.errorMessage = null;
      }
      try {
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } catch {
        this.errorMessage = ERROR_MESSAGES.AudioFailed;
        this.isMicOn = false;
      }
    },
    stopAudioStream() {
      if (this.audioStream) {
        this.audioStream.getTracks().forEach((track) => track.stop());
      }
    },
    toggleVideo() {
      this.isVideoOn = !this.isVideoOn;
      if (this.isVideoOn) {
        this.requestUserVideo();
      } else {
        this.stopVideoStream();
      }
    },
    toggleMic() {
      this.isMicOn = !this.isMicOn;
      if (this.isMicOn) {
        this.requestUserAudio();
      } else {
        this.stopAudioStream();
      }
    },
    handleStartMeeting() {
      console.log('Starting meeting...');
      this.$emit('join-meeting', { isMicOn: this.isMicOn, isVideoOn: this.isVideoOn });
    },
    handleLeaveMeeting() {
      console.log('Stopping meeting...');
      this.$emit('leave-meeting');
    },
    goRooms() {
      this.$router.push({ name: 'rooms', params: { communityId: this.$route.params.communityId } });
    },
    handleBBBMeeting() {
      this.goRooms();
      window.open(this.url, 'bbb');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.video-container {
  position: relative;
  width: fit-content;
  width: 100%;
  .video-player {
    width: 100%;
    border-radius: $border-radius;
  }
  .audio-visualizer {
    position: absolute;
    left: 20px;
    bottom: 15px;
  }
  .error-message {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: $black;
  }
  .actions-container {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
    .toggle-action {
      border: 1px solid $white;
      background-color: transparent;
      border-radius: 100%;
      height: 46px;
      width: 46px;
      padding: 0;
      &:hover {
        color: $white;
        background: rgba($secondary, 0.8);
      }
    }
  }
}
</style>
